import React from "react";
import styles from "./Selectedfeature.module.scss";

export const SelectedFeature = ({
  heading,
  para,
  points,
  leftImage,
  bottomImage,
}) => {
  return (
    <div className={styles.box}>
      <div className={styles.leftSide}>
        <div className={styles.leftBox}>
          <p className={styles.rightHeading}>{heading}</p>
          {para && <p className={styles.featureBoxText}>{para}</p>}
          {points && (
            <ul className={styles.pointsBox}>
              {points.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.rightSide}>
        <img src={leftImage} />
      </div>
      <div className={styles.bottomBox}>
        <img src={bottomImage} />
      </div>
    </div>
  );
};
