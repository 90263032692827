import { SelectedFeature } from "../components/SelectedFeature/Selectedfeature";
import softwareDevelopment from "../assets/softwareDevelopment.png";
import softwareDevelopmentBottom from "../assets/softwareDevelopmentBottom.png";
import cube from "../assets/cube.png";
import cubeSelected from "../assets/cubeSelected.png";
import paper from "../assets/paper.png";
import paperSelected from "../assets/paperSelected.png";
import marketing from "../assets/marketing.png";
import marketingSelected from "../assets/marketingSelected.png";
import net from "../assets/net.png";
import netSelected from "../assets/netSelected.png";

export const servicesTabs = [
  {
    text: "Custom Software Development",
    image: cube,
    selectedImage: cubeSelected,
  },
  {
    text: "Product Management",
    image: paper,
    selectedImage: paperSelected,
  },
  {
    text: "Product Development",
    image: net,
    selectedImage: netSelected,
  },
  {
    text: "Marketing Strategy",
    image: marketing,
    selectedImage: marketingSelected,
  },
];

export const services = [
  <SelectedFeature
    heading={"Custom Software Development"}
    para={
      "Using the latest AI and machine learning models, we are able to put together software development solutions that guarantee results. Here’s some of the things we develop: "
    }
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Web Application Development",
      "Mobile Development",
      "Hardware & Software Development",
      "Platform Security Architecture",
      "Solution Engineering",
      "Code Auditing",
    ]}
  />,
  <SelectedFeature
    heading={"Product Management"}
    para={
      "We believe in results - both in practice and design. Our team creates experiences that are impactful and valuable. Our design-driven process always begins with research and concludes with a product design. Here's what we can help with "
    }
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Research",
      "User study",
      "User flow",
      "Storyboarding",
      "User Experience (UX)",
      "User Interface (UI)",
      "Design System",
      "Product Design",
      "Interactive Design",
      "Graphic Design",
      "User Testing",
    ]}
  />,
  <SelectedFeature
    heading={"Product Development"}
    para={
      "We use a product-centric approach to optimize the value addition of your business. With our data and consulting expertise, we bring forth strategies that are revolutionary. Here's what we help with"
    }
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Business Optimization",
      "Product Strategy",
      "Product Management",
      "DevOps Enablement",
      "Value Engineering",
    ]}
  />,
  <SelectedFeature
    heading={"Marketing Strategy"}
    para={
      "From branding to marketing, you can count on us to create the image you need. We are data driven, and use analytics to create and execute marketing plans to help you grow. We understand the role social media plays which is why we help curate content that allows you to have a relevant social media presence throughout all your platforms:"
    }
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Marketing Strategy",
      "Social Media Marketing",
      "Social Media Strategy",
      "Advertising",
      "Digital Marketing",
      "Content Curation",
      "SEO",
      "Editing",
      "Copywriting",
    ]}
  />,
];
