import React from "react";
import styles from "./Buttons.module.scss";

export const SmallButton = ({ classname = "", text, icon, ...props }) => {
  return (
    <button {...props} className={`${classname} ${styles.smallBox}`}>
      <p>{text}</p>

      {icon && <img style={{ marginLeft: "1rem", width: "2rem" }} src={icon} />}
    </button>
  );
};
