import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PageContainer } from "./containers/PageContainer";
import { HirePage } from "./containers/Pages/HirePage";
import { HomePage } from "./containers/Pages/HomePage";
import { HIRE, HOME } from "./routesMap";

export const Routes = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <PageContainer>
          <Route exact path={HOME} component={HomePage} />
          <Route exact path={HIRE} component={HirePage} />
        </PageContainer>
      </Switch>
    </Router>
  );
};
