import React, { useState } from "react";
import { hireCategories, hireTabs } from "../../../db/hiring";
import { SmallButton } from "../../Buttons/SmallButton";
import { FeatureSelector } from "../../FeatureSelector/FeatureSelector";
import FeatureSelectorStyle from "../../FeatureSelector/FeatureSelector.module.scss";
import styles from "./HireSecondContent.module.scss";
import whiteArrowRight from "../../../assets/whiteArrowRight.png";
import secondContentStyles from "../../SecondContent/SecondContent.module.scss";
import { HowWeDoItBox } from "../../SecondContent/SecondContent";
import humanTime from "../../../assets/humanTime.png";
import humanMachine from "../../../assets/humanMachine.png";
import starSheet from "../../../assets/starSheet.png";
import girlUsingLaptop from "../../../assets/girlUsingLaptop.png";
import chartWorking from "../../../assets/chartWorking.png";
import { TwoSideBox } from "../../TwoSideBox/TwoSideBox";
import strategyBoard from "../../../assets/strategyBoard.png";
import track from "../../../assets/track.png";
import peopleBoard from "../../../assets/peopleBoard.png";

const Rightside = () => (
  <div className={styles.rightBox}>
    <p className={styles.rightHeading}>Skills Assessment</p>
    <p className={styles.subHeading}>
      All Proof consultants are rigorously screened so you get the best talent
    </p>
    <HowWeDoItBox
      white
      image={strategyBoard}
      heading={"Deep Technical Vetting"}
      para={"We support startups that are on a mission to create impact"}
    />
    <HowWeDoItBox
      white
      image={track}
      heading={"Full Project-Lifecycle Management"}
      para={
        "We support startups that are on a mission to create something impactful"
      }
    />
    <HowWeDoItBox
      white
      image={peopleBoard}
      heading={"Senior-Level Experience"}
      para={
        "We give you people that are vetted for the tasks you need completed"
      }
    />
  </div>
);

export const HireSecondContent = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className={styles.backgroundBox}>
      <FeatureSelector
        heading={"Most Popular Categories"}
        subHeading={
          "From implementation to execution, we’ve got you covered, every step of the way."
        }
        data={hireTabs}
        setSelected={setSelected}
        selected={selected}
        component={hireCategories[selected]}
      />

      <div className={secondContentStyles.rectangleContainer}>
        {/* Ready to get started */}
        <div className={secondContentStyles.howWeDoItBox}>
          <div className={secondContentStyles.contactUsBox}>
            <div className={secondContentStyles.readyBox}>
              <div className={secondContentStyles.readyContainer}>
                <p className={secondContentStyles.readyHeading}>
                  Ready to get started?
                </p>
                <img src={whiteArrowRight} />
              </div>
            </div>
            <div className={secondContentStyles.contactBox}>
              <SmallButton
                icon={whiteArrowRight}
                text={"Contact Us"}
                classname={secondContentStyles.button}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.hiringbox}>
        <div className={FeatureSelectorStyle.box}>
          <p className={FeatureSelectorStyle.heading}>Our Hiring Services</p>
          <div className={FeatureSelectorStyle.underline} />
          <p className={FeatureSelectorStyle.subHeading}>
            Our flexible services empower our customers to hire the best talent
            quickly, full-time or contract.
          </p>
        </div>

        <div className={secondContentStyles.howWeDoItBox}>
          <div className={secondContentStyles.leftImage}>
            <img src={girlUsingLaptop} />
          </div>
          <div className={secondContentStyles.leftImage}>
            <HowWeDoItBox
              image={humanTime}
              heading={"Technology"}
              para={
                "We use technology driven solutions to solve some of the most compex problems."
              }
            />
            <HowWeDoItBox
              image={starSheet}
              heading={"Artificial Intelligence"}
              para={
                "We build and launch AI capabilities in softwares for automated solutions."
              }
            />
            <HowWeDoItBox
              image={humanMachine}
              heading={"Machine Learning"}
              para={
                "Our machine learning solutions accelerate business development by yielding immediate and long-term benefits."
              }
            />
          </div>
        </div>
      </div>

      <div className={styles.twoSideBox}>
        <TwoSideBox rightComponent={<Rightside />} leftImage={chartWorking} />
      </div>
    </div>
  );
};
