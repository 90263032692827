import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { SmallButton } from "../Buttons/SmallButton";
import { Input } from "../Inputs/Input";
import styles from "./SixthContent.module.scss";

export const SixthContent = () => {
  const ref = useRef(null);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash.includes("contact")) {
      ref.current.scrollIntoView();
    }
  }, [hash]);

  return (
    <div ref={ref} className={styles.box}>
      <div className={styles.container}>
        <div className={styles.containerBox}>
          <div className={styles.textBox}>
            <p className={styles.heading}>Ready to get started?</p>
            <p className={styles.subHeading}>
              Ut porttitor tincidunt sapien, elementum elementum turpis
              hendrerit non.
            </p>
          </div>

          <div className={styles.formBox}>
            <p className={styles.formHeading}>Let’s get started</p>
            <br />
            <Input placeholder={"Email"} />
            <Input placeholder={"First Name"} />
            <Input placeholder={"Last Name"} />
            <Input placeholder={"Phone Number"} />
            <SmallButton text={"Get Started"} classname={styles.formButton} />
          </div>
        </div>
      </div>
    </div>
  );
};
