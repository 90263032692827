import React from "react";
import styles from "./SecondContent.module.scss";
import allInOneConsultancy from "../../assets/allInOneConsultancy.svg";
import supportStartups from "../../assets/supportStartups.svg";
import vettedPeople from "../../assets/vettedPeople.svg";
import downArrow from "../../assets/downArrow.png";
import robotBackground from "../../assets/robotBackground.png";
import chip from "../../assets/chip.png";
import brain from "../../assets/brain.png";
import bookMachine from "../../assets/bookMachine.png";
import server from "../../assets/servers.png";
import whiteArrowRight from "../../assets/whiteArrowRight.png";
import { SmallButton } from "../Buttons/SmallButton";

const FeatureBox = ({ image, heading, para }) => (
  <div className={styles.featureContainer}>
    <img src={image} />
    <div className={styles.featureRightBox}>
      <p className={styles.featureBoxHeading}>{heading}</p>
      <p className={styles.featureBoxText}>{para}</p>
      <div className={styles.arrowBox}>
        <img src={downArrow} />
      </div>
    </div>
  </div>
);

export const HowWeDoItBox = ({ image, heading, para, white }) => (
  <div className={styles.HowWeDoItContainer}>
    <img src={image} />
    <div className={styles.featureRightBox}>
      <p
        style={{ color: white && "white" }}
        className={styles.featureBoxHeading}
      >
        {heading}
      </p>
      <p style={{ color: white && "white" }} className={styles.featureBoxText}>
        {para}
      </p>
    </div>
  </div>
);

export const SecondContent = () => {
  return (
    <div className={styles.backgroundBox}>
      <div className={styles.rectangleContainer}>
        <div className={styles.featureBox}>
          <FeatureBox
            image={allInOneConsultancy}
            heading={"All in one consultancy"}
            para={"From start to finish, we are here every step of the way"}
          />
          <FeatureBox
            image={supportStartups}
            heading={"Support impactful startups"}
            para={
              "We support startups that are on a mission to create something impactful"
            }
          />
          <FeatureBox
            image={vettedPeople}
            heading={"Vetted people"}
            para={
              "We give you people that are vetted for the tasks you need completed"
            }
          />
        </div>

        {/* how we do it */}
        <div className={styles.howWeDoItBox}>
          <div className={styles.leftImage}>
            <img src={robotBackground} />
          </div>
          <div className={styles.leftImage}>
            <p className={styles.rightHeading}>How we do it</p>
            <HowWeDoItBox
              image={chip}
              heading={"Technology"}
              para={
                "We use technology driven solutions to solve some of the most compex problems."
              }
            />
            <HowWeDoItBox
              image={brain}
              heading={"Artificial Intelligence"}
              para={
                "We build and launch AI capabilities in softwares for automated solutions."
              }
            />
            <HowWeDoItBox
              image={bookMachine}
              heading={"Machine Learning"}
              para={
                "Our machine learning solutions accelerate business development by yielding immediate and long-term benefits."
              }
            />
            <HowWeDoItBox
              image={server}
              heading={"Blockchain"}
              para={
                "We use blockchain to develop end-to-end digital solutions such as securing data and distributed ledger technologies."
              }
            />
          </div>
        </div>

        {/* ready to get started */}
        <div className={styles.howWeDoItBox}>
          <div className={styles.contactUsBox}>
            <div className={styles.readyBox}>
              <div className={styles.readyContainer}>
                <p className={styles.readyHeading}>Ready to get started?</p>
                <img src={whiteArrowRight} />
              </div>
            </div>
            <div className={styles.contactBox}>
              <SmallButton
                icon={whiteArrowRight}
                text={"Contact Us"}
                classname={styles.button}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <p className={firstStyle.subHeading}>What we do</p>

<div className={styles.imagebox}>
  <div className={styles.subBox}>
    <img className={styles.grayBox} src={grayBox} />
  </div>
  <div className={styles.subTextBox}>
    <p className={styles.imageHeading}>We develop products</p>
    <p className={styles.imageText}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum consectetur. Nulla euismod, justo non pulvinar
      scelerisque, quam purus dignissim arcu, quis porta sem dui non dui.
      <br />
      <br />
      Cras vehicula, libero eget mattis fermentum, dui odio iaculis sem,
      in suscipit libero arcu sit amet massa. Ut porttitor tincidunt
      sapien, elementum elementum turpis hendrerit non.
    </p>
  </div>
</div>

<div className={styles.imagebox}>
  <div className={styles.subTextBox}>
    <p className={styles.imageHeading}>Custom software development</p>
    <p className={styles.imageText}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum consectetur. Nulla euismod, justo non pulvinar
      scelerisque, quam purus dignissim arcu, quis porta sem dui non dui.
      <br />
      <br />
      Cras vehicula, libero eget mattis fermentum, dui odio iaculis sem,
      in suscipit libero arcu sit amet massa. Ut porttitor tincidunt
      sapien, elementum elementum turpis hendrerit non.
    </p>
  </div>

  <div className={styles.subBox}>
    <img className={styles.grayBox} src={grayBox} />
  </div>
</div>

<div className={styles.imagebox}>
  <div className={styles.subBox}>
    <img className={styles.grayBox} src={grayBox} />
  </div>
  <div className={styles.subTextBox}>
    <p className={styles.imageHeading}>Build solutions</p>
    <p className={styles.imageText}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum consectetur. Nulla euismod, justo non pulvinar
      scelerisque, quam purus dignissim arcu, quis porta sem dui non dui.
      <br />
      <br />
      Cras vehicula, libero eget mattis fermentum, dui odio iaculis sem,
      in suscipit libero arcu sit amet massa. Ut porttitor tincidunt
      sapien, elementum elementum turpis hendrerit non.
    </p>
  </div>
</div>

<div className={styles.imagebox}>
  <div className={styles.subTextBox}>
    <p className={styles.imageHeading}>Incubate companies</p>
    <p className={styles.imageText}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum consectetur. Nulla euismod, justo non pulvinar
      scelerisque, quam purus dignissim arcu, quis porta sem dui non dui.
      <br />
      <br />
      Cras vehicula, libero eget mattis fermentum, dui odio iaculis sem,
      in suscipit libero arcu sit amet massa. Ut porttitor tincidunt
      sapien, elementum elementum turpis hendrerit non.
    </p>
  </div>

  <div className={styles.subBox}>
    <img className={styles.grayBox} src={grayBox} />
  </div>
</div> */
}
