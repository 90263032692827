import React from "react";
import styles from "./FeatureSelector.module.scss";
import polygon from "../../assets/Polygon.png";

export const FeatureSelector = ({
  heading,
  subHeading,
  data,
  selected,
  setSelected,
  component,
}) => {
  return (
    <div className={styles.box}>
      <p className={styles.heading}>{heading}</p>
      <div className={styles.underline} />
      <p className={styles.subHeading}>{subHeading}</p>

      <div className={styles.selectorBox}>
        {data.map((item, i) => (
          <div className={styles.selectBox}>
            <div
              onClick={() => setSelected(i)}
              className={
                selected === i ? styles.boxSelected : styles.boxUnselected
              }
            >
              <img src={selected === i ? item.selectedImage : item.image} />

              <p>{item.text}</p>
            </div>
            <div
              className={
                selected === i ? styles.polygon : styles.polygonUnselcted
              }
            >
              <img src={polygon} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.component}>{component}</div>
    </div>
  );
};
