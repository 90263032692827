import { SelectedFeature } from "../components/SelectedFeature/Selectedfeature";
import softwareDevelopment from "../assets/softwareDevelopment.png";
import softwareDevelopmentBottom from "../assets/softwareDevelopmentBottom.png";
import cube from "../assets/cube.png";
import cubeSelected from "../assets/cubeSelected.png";
import paper from "../assets/paper.png";
import paperSelected from "../assets/paperSelected.png";
import marketing from "../assets/marketing.png";
import marketingSelected from "../assets/marketingSelected.png";
import net from "../assets/net.png";
import netSelected from "../assets/netSelected.png";

export const hireTabs = [
  {
    text: "Developers",
    image: cube,
    selectedImage: cubeSelected,
  },
  {
    text: "Cloud Engineers",
    image: paper,
    selectedImage: paperSelected,
  },
  {
    text: "Product Mangers",
    image: net,
    selectedImage: netSelected,
  },
  {
    text: "Designers",
    image: marketing,
    selectedImage: marketingSelected,
  },
];

export const hireCategories = [
  <SelectedFeature
    heading={"Developers"}
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Full-Stack Engineers",
      "Front-End Engineers",
      "Back-End Engineers",
    ]}
  />,
  <SelectedFeature
    heading={"Cloud Engineers"}
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Data Analysts",
      "Data Engineers",
      "Data Architects",
      "Business Intelligence Specialists",
    ]}
  />,
  <SelectedFeature
    heading={"Product Mangers"}
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={["Project Manager", "Program Manager", "Product Leader"]}
  />,
  <SelectedFeature
    heading={"Designers"}
    leftImage={softwareDevelopment}
    bottomImage={softwareDevelopmentBottom}
    points={[
      "Product Designers",
      "User Interface Designer",
      "User Experience Designer",
      "Graphic Designer",
      "Visual Designer",
    ]}
  />,
];
