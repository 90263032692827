import React from "react";
import styles from "./Footer.module.scss";
import logo from "../../assets/logoWhite.png";
import { SmallButton } from "../Buttons/SmallButton";
import whiteArrowRight from "../../assets/whiteArrowRight.png";
import { withRouter } from "react-router-dom";

export const Footer = withRouter((props) => {
  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <div>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => props.history.push("/")}
            src={logo}
          />
          <div className={styles.leftSide}>
            <div className={styles.menuBox}>
              <p onClick={() => props.history.push("/")}>Home</p>
            </div>
            <div className={styles.menuBox}>
              <p onClick={() => props.history.push("/#services")}>Services</p>
            </div>
            <div className={styles.menuBox}>
              <p onClick={() => props.history.push("/hire")}>Hire</p>
            </div>
            <div className={styles.menuBox}>
              <p>Incubator</p>
            </div>
          </div>
        </div>

        <div>
          <SmallButton
            onClick={() => props.history.push("/#contact")}
            icon={whiteArrowRight}
            text={"Contact Us"}
            classname={styles.button}
          />
          <div className={styles.menuBox}>
            <p>844 HAURAKI</p>
          </div>
        </div>
      </div>
    </div>
  );
});
