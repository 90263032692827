import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import logo from "../../assets/logo.svg";
import menuIcon from "../../assets/menuIcon.png";
import whiteArrowRight from "../../assets/whiteArrowRight.png";
import { SmallButton } from "../Buttons/SmallButton";
import { useWindowSize } from "../../hooks/useWindowize";
import { useHistory, withRouter } from "react-router-dom";

export const Header = withRouter((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/") {
      setSelectedMenu(0);
      return;
    }
    if (history.location.pathname === "/hire") {
      setSelectedMenu(2);
      return;
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (!isOpen) return;

    const close = () => setIsOpen(false);
    window.addEventListener("click", close);

    return () => {
      window.removeEventListener("click", close);
    };
  }, [isOpen]);

  return (
    <>
      <div className={styles.box}>
        <div className={styles.leftSide}>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => props.history.push("/")}
            src={logo}
            alt={"logo"}
          />

          <div className={styles.rightSide}>
            <div
              className={`${selectedMenu === 0 && styles.selected} ${
                styles.menuBoxWithBorder
              }`}
            >
              <p onClick={() => props.history.push("/")}>Home</p>
            </div>
            <div
              className={`${selectedMenu === 1 && styles.selected} ${
                styles.menuBoxWithBorder
              }`}
            >
              <p onClick={() => props.history.push("/#services")}>Services</p>
            </div>
            <div
              className={`${selectedMenu === 2 && styles.selected} ${
                styles.menuBoxWithBorder
              }`}
            >
              <p onClick={() => props.history.push("/hire")}>Hire</p>
            </div>
            <div
              className={`${selectedMenu === 3 && styles.selected} ${
                styles.menuBoxWithBorder
              }`}
            >
              <p>Incubator</p>
            </div>
          </div>
        </div>

        <div
          onClick={() => setIsOpen(!isOpen)}
          className={styles.dropDownMenuIcon}
        >
          <img className={styles.menuIcon} src={menuIcon} alt={"menu"} />
        </div>

        <div className={styles.rightButtons}>
          <p>844 HAURAKI</p>

          <SmallButton
            onClick={() => props.history.push("/#contact")}
            icon={whiteArrowRight}
            text={"Contact Us"}
            classname={styles.button}
          />
        </div>
      </div>
      {isOpen && width < 1000 && (
        <div className={styles.dropDownMenuBox}>
          <div className={styles.dropDownMenuItem}>
            <p>Services</p>
          </div>
          <div className={styles.dropDownMenuItem}>
            <p onClick={() => props.history.push("/hire")}>Hire</p>
          </div>
          <div className={styles.dropDownMenuItem}>
            <p>Incubator</p>
          </div>

          <div className={styles.dropDownButton}>
            <p>844 HAURAKI</p>
            <SmallButton
              icon={whiteArrowRight}
              text={"Contact Us"}
              classname={styles.button}
            />
          </div>
        </div>
      )}
    </>
  );
});
