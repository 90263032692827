import React from "react";
import styles from "./FifthContent.module.scss";
import arrow from "../../assets/arrow-right.svg";
import ubf from "../../assets/logos/ubf.png";
import Zewst from "../../assets/logos/Zewst.png";
import ey from "../../assets/logos/ey.png";
import pwc from "../../assets/logos/pwc.png";
import bitwell from "../../assets/logos/bitwell.png";
import centrality from "../../assets/logos/centrality.png";
import mg from "../../assets/logos/mg.png";
import british from "../../assets/logos/british.png";
import cortell from "../../assets/logos/cortell.png";
import sass from "../../assets/logos/sass.png";
import consult from "../../assets/logos/consult.png";
import mindfull from "../../assets/logos/mindfull.png";
import ntt from "../../assets/logos/ntt.png";

export const FifthContent = () => {
  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.textBox}>
            <img src={ubf} />
          </div>
          <div className={styles.textBox}>
            <img src={Zewst} />
          </div>
          <div className={styles.textBox}>
            <img src={ey} />
          </div>
          <div className={styles.textBox}>
            <img src={pwc} />
          </div>
          <div className={styles.textBox}>
            <img src={bitwell} />
          </div>
          <div className={styles.textBox}>
            <img src={centrality} />
          </div>
          <div className={styles.textBox}>
            <img src={mg} />
          </div>
          <div className={styles.textBox}>
            <img src={british} />
          </div>
          <div className={styles.textBox}>
            <img src={cortell} />
          </div>
          <div className={styles.textBox}>
            <img src={sass} />
          </div>
          <div className={styles.textBox}>
            <img src={consult} />
          </div>
          <div className={styles.textBox}>
            <img src={mindfull} />
          </div>
          <div className={styles.textBox}>
            <img src={ntt} />
          </div>
          <div className={styles.textBoxnoHover}></div>
          <div className={styles.textBoxnoHover}></div>
        </div>
      </div>
    </div>
  );
};
