import React from "react";
import { FirstContent } from "../../components/FirstContent/FirstContent";
import { PageContainer } from "../PageContainer";
import { SecondContent } from "../../components/SecondContent/SecondContent";
import { ThirdContent } from "../../components/ThirdContent/ThirdContent";
import { SixthContent } from "../../components/SixthContent/SixthContent";
import { HireSecondContent } from "../../components/Hire/HireSecondContent/HireSecondContent";

export const HirePage = () => {
  return (
    <>
      <FirstContent
        text={"Hire the best tech talent the industry has to offer"}
      />
      <HireSecondContent />
      <SixthContent />
    </>
  );
};
