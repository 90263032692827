import React, { useEffect, useRef, useState } from "react";
import { FeatureSelector } from "../FeatureSelector/FeatureSelector";
import styles from "./ThirdContent.module.scss";
import { services, servicesTabs } from "../../db/Services";
import { TwoSideBox } from "../TwoSideBox/TwoSideBox";
import brainImage from "../../assets/brainImage.jpg";
import happy1 from "../../assets/happy1.png";
import happy2 from "../../assets/happy2.png";
import happy3 from "../../assets/happy3.png";
import { FifthContent } from "../FifthContent/FifthContent";
import { useHistory, useLocation } from "react-router-dom";

const RightSide = () => (
  <div className={styles.rightBox}>
    <p className={styles.rightHeading}>Our Philosophy</p>
    <p className={styles.heading}>Our Core Values & Principles</p>
    <p className={styles.featureBoxText}>
      We believe in solutions that are impactful. That’s why we use the latest
      engineering techniques paired with our innovation intuition to help create
      long-lasting solutions. We take pride in the fact that we’re able to help
      companies at whatever stage they’re in - beginning to end we never leave
      you hanging. Every challenge is uniquely complex but solvable. Our teams
      work together to deliver exceptional, technology-driven results.
    </p>
  </div>
);

export const ThirdContent = () => {
  const [selected, setSelected] = useState(0);

  const ref = useRef(null);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash.includes("services")) {
      ref.current.scrollIntoView();
    }
  }, [hash]);

  return (
    <div ref={ref} className={styles.backgroundBox}>
      <FeatureSelector
        heading={"Our Services"}
        subHeading={
          "From implementation to execution, we’ve got you covered, every step of the way."
        }
        data={servicesTabs}
        setSelected={setSelected}
        selected={selected}
        component={services[selected]}
      />

      <div className={styles.twoSideBox}>
        <TwoSideBox rightComponent={<RightSide />} leftImage={brainImage} />
      </div>

      <div className={styles.customerBox}>
        <div className={styles.customerContainer}>
          <p className={styles.customerHeading}>Our Customers</p>
          <div className={styles.underline} />

          <p className={styles.customerSubHeading}>
            From implementation to execution, we’ve got you covered, every step
            of the way.
          </p>

          <div className={styles.customersListBox}>
            <FifthContent />
          </div>
        </div>
      </div>

      <div className={styles.happyBox}>
        <img className={styles.happyImage} src={happy1} />
        <img className={styles.happyImage} src={happy2} />
        <img className={styles.happyImage} src={happy3} />
      </div>
    </div>
  );
};

{
  /* <p className={firstStyle.subHeading}>How we do it</p>
<div className={styles.container}>
  <div className={styles.imageBox}>
    <img src={roundBox} className={styles.image} />

    <p className={styles.heading}>Technology</p>

    <p className={styles.text}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum.
    </p>
  </div>

  <div className={styles.imageBox}>
    <img src={roundBox} className={styles.image} />

    <p className={styles.heading}>Build solutions</p>

    <p className={styles.text}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum.
    </p>
  </div>
  <div className={styles.imageBox}>
    <img src={roundBox} className={styles.image} />

    <p className={styles.heading}>Incubate companies</p>

    <p className={styles.text}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum.
    </p>
  </div>
  <div className={styles.imageBox}>
    <img src={roundBox} className={styles.image} />

    <p className={styles.heading}>Artificial Intelligence</p>

    <p className={styles.text}>
      Donec eu pretium velit. Maecenas non tortor ligula. Sed ullamcorper
      elementum.
    </p>
  </div>
</div> */
}
