import React from "react";
import { FirstContent } from "../../components/FirstContent/FirstContent";
import { PageContainer } from "../PageContainer";
import { SecondContent } from "../../components/SecondContent/SecondContent";
import { ThirdContent } from "../../components/ThirdContent/ThirdContent";
import { SixthContent } from "../../components/SixthContent/SixthContent";

export const HomePage = () => {
  return (
    <>
      <FirstContent
        text={"Building Solutions to Create The Change That Matters"}
      />
      <SecondContent />
      <ThirdContent />
      <SixthContent />
    </>
  );
};
