import React from "react";
import styles from "./FirstContent.module.scss";

export const FirstContent = ({ text }) => {
  return (
    <div className={styles.imageBox}>
      <p>{text}</p>
    </div>
  );
};

{
  /* <div className={styles.leftBox}>
<div className={styles.leftContainer}>
  <p className={styles.heading}>Agile Innovation</p>
  <p className={styles.subHeading}>
    We help innovators to successfully build and launch new companies
  </p>

  <p className={styles.subText}>
    We’re an innovation and engineering firm that solves complex
    challenges for the world’s most admired companies.
  </p>

  <SmallButton text={"Contact Us"} classname={styles.button} />
</div>
</div>
<div className={styles.rightBox}>
<img className={styles.image} src={office} />
<img className={styles.group} src={group} />
</div> */
}
