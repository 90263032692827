import React from "react";
import styles from "./TwoSideBox.module.scss";

export const TwoSideBox = ({ leftImage, rightComponent }) => {
  return (
    <div className={styles.box}>
      <div className={styles.leftBox}>
        <img src={leftImage} />
      </div>
      <div className={styles.rightBox}>{rightComponent}</div>
    </div>
  );
};
